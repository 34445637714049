import React, { useMemo, useState } from "react";
import { Breadcrumb, Row, Col, Card } from "react-bootstrap";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { useMutation, ApolloError } from "@apollo/client";
import { modifyAssets } from "graphql/mutations";
import gql from "graphql-tag";
import * as yup from "yup";
import AssetsInputs from "./AssetsInputs";
import { useParams } from "react-router";
import {
  useGetAssets,
  useGetAssetsInGroup,
} from "utils/connectors/assetsConnectors";
import {
  ModifyAssetsMutationVariables,
  ModifyAssetsMutation,
  UpdateAssetsInput,
} from "API";
import Loader from "common/Loader";
import { RelatedArtifacts } from "common/RelatedItems";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { LinkContainer } from "react-router-bootstrap";
import AssetsThreatsInputs from "./AssetsThreatsInputs";
import { useGetThreatsInGroup } from "utils/connectors/threatsConnectors";
import { AssetsPHIRecordInputForm } from "./AssetsPHIRecordInput";
import AssetsAnalysis from "./AssetsAnalysis";
import { AttributeDateDisplay, HasDisplayDates } from "common/FormExtras";
import { StickyHeader } from "common/StickyHeader";
import GenerateReport from "utils/docx-export/GenerateReport";
import { assetsReportSchema } from "./assetsReportSchema";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import useSetTitle from "utils/useSetTitle";
import { AssetAnalysisTable } from "./AssetAnalysisTable";

export const EditAssets: React.FC<{
  onComplete?: Function;
}> = () => {
  const { id } = useParams<{ id: string; tab: string }>();
  const { assets, refetch } = useGetAssets(id);
  useSetTitle(`Asset${(assets?.title && ` - ${assets.title}`) || ""}`);
  const { threats } = useGetThreatsInGroup();
  const assetsDataInGroup = useGetAssetsInGroup();
  const rightSidebarCollapsed = localStorage.getItem("rightsidebar-collapsed");
  const [isExpanded, setIsExpanded] = useState(!rightSidebarCollapsed);

  const modifyAssetSchema = useMemo(() => {
    return yup.object<Partial<UpdateAssetsInput>>({
      title: yup
        .string()
        .default("")
        .required()
        .label("Name")
        .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
        .test("check-duplication", "Asset title already exists!", (value) => {
          return !isUniqueTitle(
            assetsDataInGroup?.assets?.filter((i) => i.id !== assets?.id),
            value
          );
        }),
      qty: yup
        .number()
        .label("Qty")
        .default(assets?.qty || 0)
        .typeError("Please enter a number.")
        .min(0, "Qty must be a positive number"),
      owner: yup.string().label("Owner").required(),
    });
  }, [assets]);

  const [mutationModifyAssets] = useMutation<
    ModifyAssetsMutation,
    ModifyAssetsMutationVariables
  >(gql(modifyAssets), {
    onCompleted: () => {
      ToastifyQueue("Asset Updated Successfully.", "success");
      refetch();
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  return (
    (assets === undefined && <Loader />) || (
      <>
        <HGForm
          key="form_editAssets"
          onSubmit={async (input) => {
            return mutationModifyAssets({
              variables: {
                input: {
                  ...input,
                  id,
                },
              },
            });
          }}
          editToggle
          resolver={modifyAssetSchema}
        >
          <StickyHeader>
            <Col xs="12" className="pr-0">
              <Breadcrumb>
                <LinkContainer to="/assessments/assetManagement/asset" exact>
                  <Breadcrumb.Item>Assets</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active={false}>
                  {assets?.title}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Row>
              <Col xs="6" className="pr-0">
                <h3>Edit Assets</h3>
              </Col>
              <Col xs="6">
                <div style={{ marginLeft: "200px" }}>
                  {" "}
                  <AttributeDateDisplay item={assets as HasDisplayDates} />{" "}
                </div>
              </Col>
            </Row>
          </StickyHeader>
          <Row className="mt-205">
            <Col xs="12">
              <span className="d-block text-right">
                {assets !== undefined && (
                  <GenerateReport
                    type="Single"
                    item={assets}
                    schema={assetsReportSchema(threats)()}
                  />
                )}
              </span>
            </Col>
            <Col xs={isExpanded ? 12 : 8}>
              <Card>
                <Card.Header>
                  <HGToggleSubmit className="float-right" />
                  Details
                </Card.Header>
                <Card.Body>
                  <Row>
                    <AssetsInputs assets={assets} />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <div className="aside">
              <RelatedArtifacts
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                refetchItem={refetch}
              />
            </div>
          </Row>
        </HGForm>
        <Row className="px-2">
          <Col xs={isExpanded ? 12 : 8}>
            <AssetsAnalysis />
            {assets?.assetsAnalysisOutput && (
              <AssetAnalysisTable asset={assets} />
            )}
            <AssetsPHIRecordInputForm
              mutationModifyAssets={mutationModifyAssets}
              assets={assets}
            />
            <AssetsThreatsInputs assetID={id} mode="Details" />
          </Col>
        </Row>
      </>
    )
  );
};
