// @ts-nocheck
import { DateTime } from "luxon";
import _ from "lodash";
import { GetActionPlanv3Query, Status } from "API";
import { formatPriority, mapStatus } from "utils/formatStatus";

export const actionPlanStats: Array<
  (itemList: Array<GetActionPlanv3Query["getActionPlanv3"]>) => {
    label?: any;
    text?: any;
    listItems?: Array<any>;
  }
> = [
  (itemList) => ({
    label: "Status",
    text: `${itemList.length ?? "0"} total / ${
      itemList.filter((item) => {
        const _status = mapStatus(item?.status);
        return (
          _status !== Status.Closed &&
          _status !== Status.Closed_Pending_Approval
        );
      }).length ?? "0"
    } open / ${
      itemList.filter((item) => {
        const _status = mapStatus(item?.status);
        return (
          _status === Status.Closed ||
          _status === Status.Closed_Pending_Approval
        );
      }).length ?? "0"
    } closed (includes items Pending Approval)`,
  }),
  (itemList) => ({
    label: "Priority",
    listItems: _.chain(itemList)
      .groupBy("priority")
      .map((values, key) => {
        return { title: formatPriority(key), length: values.length };
      })
      .sortBy("length")
      .reverse()
      .value()
      .map((item) => `${item.length || 0} ${item.title}`),
  }),
  (itemList, { organizationUsers }) => {
    const ownerCounts = _.chain(itemList)
      .groupBy("owner")
      .map((values, key) => {
        return {
          title: key,
          length: values.length,
        };
      })
      .sortBy("length")
      .reverse()
      .value();
    const ownerDisplay = ownerCounts.map(
      (owner) =>
        `${owner.length} (${parseInt(
          (100 * owner.length) / itemList.length
        )}%) - ${
          organizationUsers.find(({ id }) => owner.title === id)?.displayName ||
          owner.title ||
          "Unassigned"
        }`
    );
    return {
      label: "Owner",
      listItems: ownerDisplay,
    };
  },
];
