import { useMutation, gql, ApolloError } from "@apollo/client";
import { ModifyAssetsMutation, ModifyAssetsMutationVariables } from "API";
import { BoxPlot, ExceedenceGraph } from "common/fair-graphs";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { runFairTaxonomy } from "common/rconsumer";
import { modifyAssets } from "graphql/mutations";
import React, { useState, useEffect } from "react";
import { Card, Button, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { useGetAssets } from "utils/connectors/assetsConnectors";
import { useGetThreatsInGroup } from "utils/connectors/threatsConnectors";
import { zipSum } from "utils/stats";

const AssetsAnalysis: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { assets, refetch } = useGetAssets(id);
  const { threats } = useGetThreatsInGroup();
  const [graphData, setGraphData] = useState() as any;
  const [mutationModifyAssets] = useMutation<
    ModifyAssetsMutation,
    ModifyAssetsMutationVariables
  >(gql(modifyAssets), {
    onCompleted: () => {
      ToastifyQueue("Analysis Run Successfully.", "success");
      refetch();
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const attachedThreats = [] as any;
  const PHI = assets?.PHIRecords ? JSON.parse(assets?.PHIRecords) : "";
  const maxValidation = !!(
    (assets as any)?.assetAndThreatsRelatedItems?.items.length > 0 &&
    assets?.PHIRecords !== null
  );
  assets?.assetAndThreatsRelatedItems?.items.map((item) => {
    attachedThreats.push({
      frequency: {
        threatEvents: {
          pertConfig: {
            min: item?.min,
            max: item?.max,
            mode: item?.mostLikely,
          },
        },
        vulnerability: {
          probability: item?.vulnerability,
        },
      },
      magnitude: {
        pertConfig: {
          min: PHI?.min,
          max: PHI?.max,
          mode: PHI?.mode,
          shape: PHI?.confidence,
        },
      },
      ThreatsDetails: {
        threatID: item?.threatID,
      },
    });
  });
  const baseData =
    graphData &&
    graphData?.map((item) => {
      // show title in graph
      const title = threats
        .filter((threat) => threat.id === item.threatID)
        .map((threat) => ({
          ...threat,
          title: `${threat.title}${threat.archived ? " (archived)" : ""}`,
        }));
      return {
        data: item?.expectedLoss,
        title: title[0]?.title,
      };
    });

  const totalAggregate =
    (baseData?.length > 0 && [
      {
        title: "Total",
        data:
          baseData.length === 0 ? [[]] : zipSum(...baseData.map((r) => r.data)),
      },
    ]) ||
    [];
  // Show graph if there is analysis

  useEffect(() => {
    setGraphData(
      assets?.assetsAnalysisOutput
        ? JSON.parse(assets?.assetsAnalysisOutput)
        : ""
    );
  }, [assets?.assetsAnalysisOutput]);

  // call runFairTaxonomy for every attached threats and perform mutation
  const callFairTaxonomy = () => {
    const _result = Promise.all(
      attachedThreats.map((item) => {
        return runFairTaxonomy(item);
      })
    ).then((data) => {
      setGraphData(data);
      return mutationModifyAssets({
        variables: {
          input: [
            {
              id,
              assetsAnalysisOutput: JSON.stringify(data),
              assetsAnalysisInput: JSON.stringify(attachedThreats),
            },
          ],
        },
      });
    });
  };

  function RunAnalysis() {
    callFairTaxonomy();
  }
  return (
    <Card>
      <Card.Header className="text-right w-100">
        <Button
          type="submit"
          disabled={!maxValidation}
          onClick={() => {
            RunAnalysis();
          }}
        >
          {" "}
          Run Analysis
        </Button>
      </Card.Header>
      <Card.Body>
        {graphData?.length > 0 ? (
          <Row>
            <ExceedenceGraph
              style={{
                backgroundColor: "white",
                border: "5px solid #f3f3f4",
                borderRadius: "15px",
              }}
              data={[...totalAggregate, ...baseData]}
              sm={6}
              xFormat={(t) => t}
              yFormat={(t) => `${t}%`}
              label="Probability of Exceedance"
              multiple
            />
            <BoxPlot
              style={{
                backgroundColor: "white",
                border: "5px solid #f3f3f4",
                borderRadius: "15px",
              }}
              data={graphData.map((item) => {
                // show title in graph
                const title = threats
                  .filter((threat) => threat.id === item.threatID)
                  .map((threat) => ({
                    ...threat,
                    title: `${threat.title}${
                      threat.archived ? " (archived)" : ""
                    }`,
                  }));
                return {
                  data: item?.expectedLoss,
                  title: title[0]?.title,
                };
              })}
              sm={6}
              label="Whisker Box Plot"
            />
          </Row>
        ) : (
          "Please set PHI and Threat values and then click Run Analysis "
        )}
      </Card.Body>
    </Card>
  );
};
export default AssetsAnalysis;
