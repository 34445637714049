import {
  Paragraph, Table, TableCell, TableLayoutType, TableRow, TextRun, WidthType, 
} from "docx"
import { createTableHeader } from "../HIPAARiskAssessment/Assets/assetsReportUtils"


export const createActionPlanTaskTableCells = (data) => {
  return new TableCell({
    children:[
      new Paragraph({
        children: [
          new TextRun({
            text: data,
          }),
        ],
      }),
    ],
  })
} 
export const createActionPlanTaskTableRows = (items) => {
  return items.map((task)=>{
    return new TableRow({
      children: [
        createActionPlanTaskTableCells(task.task.title),
        createActionPlanTaskTableCells(task.task.status),
        createActionPlanTaskTableCells(task.task.priority),
        createActionPlanTaskTableCells(task.task.dueDate),
      ],
    })
  })
}
export const createActionPlanTaskTable = (tasks) => {
  return new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows: [
      new TableRow({
        children: createTableHeader(["Title", "Status", "Priority", "Due Date"]),
      }),
      ...createActionPlanTaskTableRows(tasks),
    ],
  })    
}