import { usePermissions } from "common/Permissions";
import React from "react";
import { Button } from "react-bootstrap";
import {
  generateReportData,
  DownloadItemProps,
} from "utils/docx-export/GenerateReport";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { DateTime } from "luxon";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { resetOlCount, getOlCount } from "../docx-export/docUtils";

export type FlexibleFilename =
  | string
  | {
      fileName: string;
      extension?: string;
    };

export type GenerateZipReport<T> = DownloadItemProps<T> & {
  directory?: string;
  fileName: FlexibleFilename;
};

export type GenerateZipProps<T> = {
  fileName: FlexibleFilename;
  reports: any;
};

const GenerateZip = function <T>(props: GenerateZipProps<T>) {
  const permissions = usePermissions();
  const zip = new JSZip();
  const getFileName = useGenerateReportFilename();

  const handleClick = async () => {
    const timestamp = DateTime.now();
    const subDirectories = {};
    resetOlCount();
    await Promise.all(
      props.reports.map(async (report) => {
        return generateReportData(permissions, report).then((fileData) => {
          const reportFileName =
            typeof report.fileName === "string"
              ? report.fileName
              : getFileName({
                  type: "filename",
                  fileName: report.fileName.fileName,
                  timestamp,
                  extension: "docx",
                });
          if (report.directory) {
            if (!subDirectories[report.directory]) {
              subDirectories[report.directory] = zip.folder(report.directory);
            }
            return subDirectories[report.directory]?.file(
              reportFileName,
              fileData,
              { base64: true }
            );
          }
          return zip?.file(reportFileName, fileData, { base64: true });
        });
      })
    );

    const fileName =
      typeof props.fileName === "string"
        ? props.fileName
        : getFileName({
            type: "filename",
            fileName: props.fileName.fileName,
            timestamp,
            extension: "zip",
          });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      saveAs(content, fileName);
    });
    // saveAs(fileData, 'testing.docx');
  };
  return (
    <Button className="mb-2 ml-2" onClick={handleClick}>
      Download Summary Report
    </Button>
  );
};

export default GenerateZip;
