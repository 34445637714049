// @ts-nocheck
import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { DateTime } from "luxon";

import DecipherRiskLogo from "common/layout/Header/DecipherRisk-dark.png";

const PDF_Table: React.FC = ({ children }) => (
  <View style={styles.table}>{children}</View>
);
const PDF_TR: React.FC = ({ children }) => (
  <View style={styles.tableRow}>{children}</View>
);
const PDF_TD: React.FC<{ style?: Object }> = ({ children, style }) => (
  <View style={styles.tableCol}>
    <Text style={{ ...styles.tableCell, ...style }}>{children}</Text>
  </View>
);
const PDF_TD_FULL = ({ children }) => (
  <View
    style={{ ...styles.tableColFull, ...styles.bold, ...styles.tableCellGray }}
  >
    <Text style={styles.tableCell}>{children}</Text>
  </View>
);

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";

const dummyData = {
  type: "__issue",
  compositeID: "21-0005",
  title: "Excess open ports on firewall",
  reportedOn: "10/20/2020",
  reportedBy: "John Pennington",
  status: "In Progress",
  owner: "John Arlinghaus",
  source: "Internal Review",
  description: "ACLs include blanket whitelisting for some applications",
  notes: "We've identified the 4 apps that need to be evaluated for port usage",
  nextReview: "03/10/2021",
  priority: "High",
  severity: "High",
  attachedItems: [
    {
      type: "__actionplan",
      compositeID: "21-0006",
      title: "Reconfigure IP whitelisting on firewall",
      description: "Need to evaluate which resources need to be adjusted",
      resources: "",
      notes: "",
      owner: "Apolonio Garcia",
      priority: "High",
      status: "In Progress",
      dueDate: "02/21/2021",
      tasks: [
        { title: "Task 1", status: "Open", dateStatus: "Past Due" },
        { title: "Task 2", status: "In Progress", dateStatus: "Other" },
      ],
    },
    {
      type: "__scenario",
      compositeID: "21-0007",
      title: "Open Firewall Ports",
      description: "Risk associated with broad port ranges in ACLs",
      notes: "",
      creationDate: "10/30/2020",
      lastRevision: "12/24/2020",
      nextRevision: "02/24/2021",
      owner: "John Pennington",
      status: "Closed",
      indicator: "Fragile",
      categoryTypes: [
        {
          title: "Threat",
          categories: [
            {
              title: "Malicious/Targeted",
              subcategories: [{ title: "Cybercriminal" }, { title: "Vendor" }],
            },
          ],
        },
        {
          title: "Event",
          categories: [
            {
              title: "Information/System Related Outcomes",
              subcategories: [{ title: "Information Access (Unauthorized)" }],
            },
          ],
        },
      ],
      summary: "Summary",
    },
  ],
};

const report_scenarioSchema = [
  { label: "Description", key: "description" },
  { label: "Assumptions", key: "assumptions" },
  { label: "Notes", key: "notes" },
  { label: "Creation Date", key: "creationDate" },
  { label: "Last Revision", key: "lastRevision" },
  { label: "Review Date", key: "nextRevision" },
  { label: "Owner", key: "owner" },
  { label: "Status", key: "status" },
  { label: "Indicator", key: "indicator" },
  {
    label: "Categories",
    transformStandalone: (scenario) => {
      return scenario.categoryTypes?.map(
        (categoryType) =>
          (categoryType.categories?.length > 0 && (
            <View style={styles.listIndent}>
              <View style={styles.listIndent}>
                <Text>{categoryType.title}</Text>
                {categoryType.categories?.map(
                  (category) =>
                    category.subcategories?.length > 0 && (
                      <View style={styles.listIndent}>
                        <Text>{category.title}</Text>
                        {category.subcategories?.map((subcategory) => (
                          <View style={styles.listIndent}>
                            <Text>{subcategory.title}</Text>
                          </View>
                        ))}
                      </View>
                    )
                )}
              </View>
            </View>
          )) ||
          null
      );
    },
  },
  {
    label: "Summary",
    transformStandalone: (scenario) => (
      <PDF_Table>
        <PDF_TR>
          <PDF_TD />
          <PDF_TD>Minimum</PDF_TD>
          <PDF_TD>Mean</PDF_TD>
          <PDF_TD>Mode</PDF_TD>
          <PDF_TD>Maximum</PDF_TD>
        </PDF_TR>
        <PDF_TR>
          <PDF_TD_FULL>Primary</PDF_TD_FULL>
        </PDF_TR>
        <PDF_TR>
          <PDF_TD>Loss Events / Year</PDF_TD>
          <PDF_TD>0.00</PDF_TD>
          <PDF_TD>2.72</PDF_TD>
          <PDF_TD>2.00</PDF_TD>
          <PDF_TD>7.00</PDF_TD>
        </PDF_TR>
        <PDF_TR>
          <PDF_TD>Loss Magnitude</PDF_TD>
          <PDF_TD>0.00</PDF_TD>
          <PDF_TD>2.72</PDF_TD>
          <PDF_TD>2.00</PDF_TD>
          <PDF_TD>7.00</PDF_TD>
        </PDF_TR>
      </PDF_Table>
    ),
  },
];

const mapSummary = (entry) => (
  <Text>
    {entry.title}:{entry.value};{" "}
  </Text>
);

const report_actionPlanSchema = [
  { label: "Description", key: "description" },
  { label: "Resources", key: "resources" },
  { label: "Notes", key: "notes" },
  { label: "Owner", key: "owner" },
  { label: "Priority", key: "priority" },
  { label: "Status", key: "status" },
  { label: "Due Date", key: "dueDate" },
  {
    label: "Tasks (by status)",
    transform: (actionPlan) => {
      if (!actionPlan.tasks || actionPlan.tasks.length === 0) {
        return null;
      }
      const summary = [
        {
          title: "Open",
          value: actionPlan.tasks
            .filter((task) => task.status === "Open")
            .length.toString(),
        },
        {
          title: "In Progress",
          value: actionPlan.tasks
            .filter((task) => task.status === "In Progress")
            .length.toString(),
        },
        {
          title: "Closed",
          value: actionPlan.tasks
            .filter((task) => task.status === "Closed")
            .length.toString(),
        },
      ];
      return summary.map(mapSummary);
    },
  },
  {
    label: "Tasks (by time)",
    transform: (actionPlan) => {
      if (!actionPlan.tasks || actionPlan.tasks.length === 0) {
        return null;
      }
      const summary = [
        { title: "Past Due", value: 1 },
        { title: "This Month", value: 2 },
        { title: "Next Month", value: 3 },
        { title: "Other", value: 5 },
      ];

      return summary.map(mapSummary);
    },
  },
];

const report_issueSchema = [
  { label: "Reported On", key: "reportedOn" },
  { label: "Reported By", key: "reportedBy" },
  { label: "Status", key: "status" },
  { label: "Owner", key: "owner" },
  { label: "Source", key: "source" },
  { label: "Description", key: "description" },
  { label: "Notes", key: "notes" },
  { label: "Review Date", key: "nextReview" },
  { label: "Priority", key: "priority" },
  { label: "Severity", key: "severity" },
];

const Header = () => {
  return (
    <View>
      <Text style={styles.header}>Decipher Risk Summary Report</Text>
    </View>
  );
};

const TextRow = ({ label, value, standaloneVal }) => {
  return (
    (value && (
      <Text style={styles.infoText}>
        <Text style={styles.bold}>{label}</Text>:{value}
      </Text>
    )) ||
    (standaloneVal && (
      <View>
        <Text style={styles.infoText}>
          <Text style={styles.bold}>{label}</Text>:{value}
        </Text>
        {standaloneVal}
      </View>
    )) ||
    null
  );
};

const getItemType = (item) => {
  switch (item.type) {
    case "__issue":
      return { label: "Issue", schema: report_issueSchema };
    case "__actionplan":
      return { label: "Action Plan", schema: report_actionPlanSchema };
    case "__scenario":
      return { label: "Scenario", schema: report_scenarioSchema };
    default:
      return { label: "Unknown", schema: [] };
  }
};

const ItemPage = ({ item, parent }) => {
  const { label, schema } = getItemType(item);
  const primary = parent || item;
  const secondary = (parent && item) || null;
  return (
    <Page size="LETTER">
      <View style={styles.page}>
        <View>
          <Text style={styles.header}>
            {getItemType(primary).label}:{primary.title} ({primary.compositeID})
          </Text>
        </View>
        <View>
          {secondary && (
            <Text style={styles.subheader}>
              Related {label}: {secondary.title} ({secondary.compositeID})
            </Text>
          )}
        </View>

        <View style={styles.info}>
          <Text />
        </View>

        <View style={styles.info}>
          {schema.map((i) => {
            const standaloneVal =
              (i.transformStandalone && i.transformStandalone(item)) || null;
            const val =
              (i.transform && i.transform(item)) || item[i.key] || null;
            return (
              <TextRow
                label={i.label}
                value={val}
                standaloneVal={standaloneVal}
              />
            );
          })}
        </View>
      </View>
      <View style={styles.headerImage} fixed>
        <Image style={styles.headerImageImage} src={DecipherRiskLogo} />
      </View>
      <View style={styles.footer} fixed>
        <Text
          style={styles.footerText}
          render={({ pageNumber, totalPages }) =>
            `Decipher Risk Report: Page ${pageNumber} of ${totalPages} - ${dummyData.title}`
          }
          fixed
        />
        <Text style={styles.footerText} fixed>
          Created at {DateTime.local().toLocaleString(DateTime.DATETIME_SHORT)}
        </Text>
      </View>
    </Page>
  );
};

const SummaryReport = React.memo(({ data }) => {
  const liveData = [data, ...((data && data.attachedItems) || [])];
  return (
    <Document>
      {liveData.map((d, i) => (
        <ItemPage item={d} parent={(i > 0 && liveData[0]) || null} />
      ))}
    </Document>
  );
});

const styles = StyleSheet.create({
  label: {
    fontSize: 14,
  },
  value: {
    fontSize: 12,
  },
  header: {
    fontSize: 20,
  },
  headerImage: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  headerImageImage: {
    height: 40,
    opacity: 0.4,
  },
  subheader: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Helvetica-Oblique",
  },
  page: {
    fontFamily: "Helvetica",
    // flexDirection: "column",
    backgroundColor: "#ffffff",
    marginTop: 30,
    marginBottom: 30,
    paddingHorizontal: 30,
  },
  italic: {
    fontFamily: "Helvetica-Oblique",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  row: {
    // flexDirection: "row"
  },
  info: {
    padding: 5,
    fontSize: 12,
  },
  infoText: {
    margin: 5,
  },
  infoBold: {
    size: 13,
  },
  section: {
    margin: 5,
    padding: 5,
    fontSize: 12,
  },
  title: {
    fontSize: "20",
  },

  footer: {
    position: "absolute",
    bottom: 10,
    left: 20,
    fontSize: 12,
    width: "100%",
  },
  footerText: {
    color: "grey",
  },

  listIndent: {
    marginLeft: 5,
  },

  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  tableColHeader: {
    width: `${20}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCol: {
    width: `${20}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColFull: {
    width: `${100}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    padding: 5,
    fontSize: 10,
  },
  tableCellGray: {
    backgroundColor: "#eeeeee",
  },
});

export const RelatedItemReport = () => {
  return (
    <>
      <PDFViewer style={{ width: "100%", height: "900px" }}>
        <SummaryReport data={dummyData} />
      </PDFViewer>
      <PDFDownloadLink
        document={<SummaryReport data={dummyData} />}
        fileName="filetest.pdf"
      >
        {({ loading }) => (loading ? "Loading document..." : "Download now!")}
      </PDFDownloadLink>
    </>
  );
};
