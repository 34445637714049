// @ts-nocheck
import { DateTime } from "luxon";
import _ from "lodash";
import { Status } from "API";
import { mapStatus } from "utils/formatStatus";

export const issueStats: Array<
  (itemList: Array<object>) => { text: any; listItems?: Array<any> }
> = [
  (issues) => ({
    label: "Status",
    text: `${issues.length ?? "0"} total / ${
      issues.filter(({ status }) => {
        const _status = mapStatus(status);
        return (
          _status !== Status.Closed &&
          _status !== Status.Closed_Pending_Approval
        );
      }).length ?? "0"
    } open / ${
      issues.filter(({ status }) => {
        const _status = mapStatus(status);
        return (
          _status === Status.Closed ||
          _status === Status.Closed_Pending_Approval
        );
      }).length ?? "0"
    } closed (include items Pending Approval)`,
  }),
  (issues) => {
    const now = DateTime.local();
    const threshold = now.minus({ days: 90 });
    const totalIssues = issues.length;
    if (totalIssues === 0) {
      return null;
    }
    const staleIssues = issues.filter((issue) => {
      const updatedAt = DateTime.fromISO(issue.lastModified);
      return updatedAt < threshold;
    }).length;

    return {
      label: "Freshness",
      text: `${parseInt(
        (100 * staleIssues) / totalIssues
      ).toString()}% of Issues (${staleIssues} out of ${totalIssues}) are "stale" (have not been updated in over 90 days)`,
    };
  },
  (issues) => {
    const totalIssues = issues.length;
    if (totalIssues === 0) {
      return null;
    }
    const criticalSeverityIssues = issues.filter(
      (issue) => issue.severity === "Critical"
    ).length;
    const criticalPercentage = parseInt(
      (100 * criticalSeverityIssues) / totalIssues
    );
    const highSeverityIssues = issues.filter(
      (issue) => issue.severity === "Major"
    ).length;
    const highPercentage = parseInt((100 * highSeverityIssues) / totalIssues);
    const mediumSeverityIssues = issues.filter(
      (issue) => issue.severity === "Minor"
    ).length;
    const mediumPercentage = parseInt(
      (100 * mediumSeverityIssues) / totalIssues
    );
    const lowSeverityIssues = issues.filter(
      (issue) => issue.severity === "Low"
    ).length;
    const lowPercentage = parseInt((100 * lowSeverityIssues) / totalIssues);

    const notsetSeverityIssues = issues.filter(
      (issue) => issue.severity === "Null"
    ).length;
    const notsetPercentage = parseInt(
      (100 * notsetSeverityIssues) / totalIssues
    );

    return {
      label: "Severity",
      listItems: [
        `${criticalSeverityIssues} (${criticalPercentage}%) Critical`,
        `${highSeverityIssues} (${highPercentage}%) High`,
        `${mediumSeverityIssues} (${mediumPercentage}%) Medium`,
        `${lowSeverityIssues} (${lowPercentage}%) Low`,
        `${notsetSeverityIssues} (${notsetPercentage}%) Not Set`,
      ],
    };
  },
  (issues, { organizationUsers }) => {
    const ownerCounts = _.chain(issues)
      .groupBy("owner")
      .map((values, key) => {
        return {
          title: key,
          length: values.length,
        };
      })
      .sortBy("length")
      .reverse()
      .value();
    const ownerDisplay = ownerCounts.map(
      (owner) =>
        `${owner.length} (${parseInt(
          (100 * owner.length) / issues.length
        )}%) - ${
          organizationUsers.find(({ id }) => owner.title === id)?.displayName ||
          (owner.title === "Null" ? "Not Set" : "Unassigned")
        }`
    );

    return {
      label: "Owner",
      listItems: ownerDisplay,
    };
  },
  (issues, { sources }) => {
    const sourceCounts = _.chain(issues)
      .map((issue) => {
        // Normalize empty and "Null" values to "Null"
        if (issue.issueSourceId === "" || issue.issueSourceId === "Null") {
          return { ...issue, issueSourceId: "Null" };
        }
        return issue;
      })
      .groupBy("issueSourceId")
      .map((values, key) => {
        return {
          title: key,
          length: values.length,
        };
      })
      .sortBy("length")
      .reverse()
      .value();
    const sourceDisplay = sourceCounts.map(
      (source) =>
        `${source.length} (${parseInt(
          (100 * source.length) / issues.length
        )}%) - ${
          sources.find(({ id }) => source.title === id)?.title ||
          (source.title === "" || source.title === "Null"
            ? "Not Set"
            : "Unassigned")
        }`
    );

    return {
      label: "Source",
      listItems: sourceDisplay,
    };
  },
  (issues) => {
    const missingReviewDateCount = issues.filter(
      ({ nextReview }) => !nextReview
    ).length;
    const totalIssues = issues.length;

    return {
      label: "Review Date",
      text: `${parseInt(
        (100 * missingReviewDateCount) / totalIssues
      )}% (${missingReviewDateCount}/${totalIssues}) don't have review date`,
    };
  },
];
