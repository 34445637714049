import React from "react";
import { Card, Table } from "react-bootstrap";
import {
  useGetThreats,
  useGetThreatsInGroup,
} from "utils/connectors/threatsConnectors";

export const TableCell = ({ children, isHeader = false }) => {
  return isHeader ? (
    <th style={{ fontWeight: "bold" }}>{children}</th>
  ) : (
    <td>{children}</td>
  );
};

export const TableRow = ({ cells, isHeader = false }) => {
  return (
    <tr>
      {cells.map((cell, index) => (
        <TableCell key={index} isHeader={isHeader}>
          {cell}
        </TableCell>
      ))}
    </tr>
  );
};

export const TableHeader = ({ headers }) => {
  return (
    <thead>
      <TableRow cells={headers} isHeader />
    </thead>
  );
};

export const TableBody = ({ data, columnOrder }) => {
  return (
    <tbody>
      {data.map((row, index) => (
        <TableRow
          key={index}
          cells={columnOrder.map((col) => row[col] || "-")}
        />
      ))}
    </tbody>
  );
};
export const AssetAnalysisTable = ({ asset }) => {
  const { threats } = useGetThreatsInGroup();
  const threatsMagnitudes = JSON.parse(asset.assetsAnalysisOutput).map(
    (item) => {
      const { mode, ...filteredStats } = item.primaryMagnitudeStats;
      const threat = threats && threats.find((t) => t.id === item.threatID);
      return { title: threat?.title, ...filteredStats };
    }
  );
  const headers = ["", "Minimum", "Mean", "Median", "Maximum"];
  const columnOrder = ["title", "min", "mean", "median", "max"];
  return (
    <Card>
      <Card.Header>Summary of Simulation Results</Card.Header>
      <Card.Body>
        <Table className=" table-bordered">
          <TableHeader headers={headers} />
          <TableBody data={threatsMagnitudes} columnOrder={columnOrder} />
        </Table>
      </Card.Body>
    </Card>
  );
};
