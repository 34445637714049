// @ts-nocheck
import React from "react";

import { useTabbedTable } from "common/TabbedTable";
import { useIssueSchema } from "Modules/Customers/Issues/issueSchema";
import { Link } from "react-router-dom";
import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import { Card, Col, Row } from "react-bootstrap";
import { useGetIssueChangesInGroup } from "utils/connectors/issueConnectors";
import { useActionPlanSchema } from "Modules/Customers/ActionPlans/actionPlanSchema";
import { useGetActionPlanChangesInGroup } from "utils/connectors/actionPlanConnectors";
import { useGetScenarioChangesInGroup } from "utils/connectors/scenarioConnectors";
import { useRiskSchema } from "Modules/Customers/Risk/riskSchema";
import { useTabs } from "common/Tabs";
import useSetTitle from "utils/useSetTitle";

export const Changes = ({
  changes,
  schema,
  moduleID,
  moduleName,
  currentItemID,
  itemChangeID,
  moduleURL,
}) => {
  const { getOrganizationUser } = usePermissions();

  const getChanges = (changes) => {
    if (!changes) return [];
    const {
      owner,
      status,
      severity,
      priority,
      nextReview,
      nextRevision,
      ...otherChanges
    } = changes;
    const primaryChanges = Object.entries({
      owner,
      status,
      severity,
      priority,
      nextRevision: nextReview || nextRevision,
    }).filter(([key, val]) => val !== null);
    const filteredChanges = Object.entries(otherChanges).filter(
      ([key, val]) => val !== null
    );
    return Object.assign(filteredChanges, primaryChanges);
  };

  const activeChanges = changes.map((change) => ({
    ...change,
    active:
      getChanges(change[itemChangeID])?.filter(([key, val]) => !!key && !!val)
        ?.length > 0,
  }));

  const { TableUI, ColumnSortFilterUI } = useTabbedTable(
    `${moduleID}changelogColumnSortOrder`,
    activeChanges.filter(({ active }) => active),
    [
      {
        id: "timestamp",
        name: "Timestamp",
        format: ({ createdAt }) => DateTime.fromISO(createdAt),
        displayFormat: ({ createdAt }) =>
          DateTime.fromISO(createdAt).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          ),
        type: "Date",
        required: true,
        first: true,
      },
      {
        id: moduleID,
        name: moduleName,
        format: (item) => item[currentItemID].title,
        tableDisplayFormat: (item) => {
          const currentItem = item[currentItemID];
          return (
            <Link to={`/${moduleURL}/${currentItem.id}`}>
              {currentItem.title}
            </Link>
          );
        },
        required: true,
        first: true,
      },
      {
        id: "user",
        name: "User",
        format: ({ requestingUser }) =>
          getOrganizationUser(requestingUser)?.displayName,
        required: true,
        first: true,
      },
      {
        id: "changeType",
        name: "Change Type",
        format: ({ changeType }) => changeType,
        required: true,
        first: true,
      },
      {
        id: "changes",
        name: "Changes",
        format: (item) => {
          const itemChanges = item[itemChangeID];
          return getChanges(itemChanges)
            .filter(([key, val]) => !!key && !!val)
            .map(([key, val]) => {
              if (!key || !val) return null;
              const formatter = schema.find((schema) => schema.id === key);
              const formattedVal = formatter?.format?.(itemChanges) || "";
              const displayFormattedVal =
                formatter?.displayFormat?.(itemChanges) || "";
              return `${formatter?.name} ${formattedVal};${
                formatter?.type === "Date" &&
                ` ${formatter?.name} ${displayFormattedVal}`
              }`;
            })
            .reduce((a, b) => `${a} ${b}`, "");
        },
        tableDisplayFormat: (item) => {
          const itemChanges = item[itemChangeID];
          return getChanges(itemChanges)
            .filter(([key, val]) => !!key && !!val)
            .map(([key, val]) => {
              if (!key || !val) return null;
              const formatter = schema.find((schema) => schema.id === key);
              if (!formatter) return;
              return formatter?.id === "owner" ||
                formatter?.id === "status" ||
                formatter?.id === "severity" ||
                formatter?.id === "priority" ||
                formatter?.id === "nextReview" ||
                formatter?.id === "nextRevision" ? (
                <li>
                  {formatter?.name}:{" "}
                  {formatter?.tableDisplayFormat?.(itemChanges) ||
                    formatter?.displayFormat?.(itemChanges) ||
                    formatter?.format?.(itemChanges)}
                </li>
              ) : (
                <li> {formatter?.name} </li>
              );
            });
        },
        required: true,
        first: true,
      },
    ],
    null,
    { hideTabs: true }
  );

  return (
    <Card>
      <Card.Header>
        {ColumnSortFilterUI}
        {moduleName} Changes
      </Card.Header>
      <Card.Body>{TableUI}</Card.Body>
    </Card>
  );
};

export const IssueChanges = () => {
  const schema = useIssueSchema();
  const { changes } = useGetIssueChangesInGroup();

  const params = {
    schema,
    changes,
    moduleID: "issue",
    moduleName: "Issue",
    currentItemID: "currentIssue",
    itemChangeID: "issueChanges",
    moduleURL: "issues",
  };

  return <Changes {...params} />;
};

export const ActionPlanChanges = () => {
  const schema = useActionPlanSchema();
  const { changes } = useGetActionPlanChangesInGroup();

  const params = {
    schema,
    changes,
    moduleID: "actionplan",
    moduleName: "Action Plan",
    currentItemID: "currentActionPlan",
    itemChangeID: "actionplanChanges",
    moduleURL: "actionplans",
  };

  return <Changes {...params} />;
};

export const ScenarioChanges = () => {
  const schema = useRiskSchema();
  const { changes } = useGetScenarioChangesInGroup();

  const params = {
    schema,
    changes,
    moduleID: "scenario",
    moduleName: "Scenario",
    currentItemID: "currentScenario",
    itemChangeID: "scenarioChanges",
    moduleURL: "risk",
  };

  return <Changes {...params} />;
};

export const ChangeLog = () => {
  const [tab, _setTab, TabUI] = useTabs([
    "Issues",
    "Action Plans",
    "Scenarios",
  ]);
  useSetTitle("Change Log");
  let TabElements = { TableUI: <IssueChanges /> };
  if (tab === "Issues") {
    TabElements = { TableUI: <IssueChanges /> };
  } else if (tab === "Action Plans") {
    TabElements = { TableUI: <ActionPlanChanges /> };
  } else {
    TabElements = { TableUI: <ScenarioChanges /> };
  }

  return (
    <>
      <Row className="dashboard-header" style={{ paddingBottom: 0 }}>
        <Col xs="12">
          <h3>Change Log</h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <TabUI />
            </Card.Header>
            <Card.Body>{TabElements.TableUI}</Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
