import { useMutation, gql, ApolloError } from "@apollo/client";
import { ModifySourceMutationVariables, UpdateSourceInput } from "API";
import { HGForm, HGFormSubmit, HGToggleSubmit } from "common/HGForm";
import { HGRichText } from "common/HGForm/HGRichText";
import { usePermissions } from "common/Permissions";
import { modifySource } from "graphql/mutations";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as yup from "yup";
import { cancelPopupMessage } from "utils/globalVariables";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import { useGetSourcesInGroup } from "../Issues/IssuePage/Sources";
import { isUniqueTitle } from "common/formFieldsValidationHelpers";
import { HGInputBox, HGNumber } from "common/HGForm/Inputs/index";

export const ModifySourceButton = ({ source, onSave }) => {
  const { group, organization, groupRole } = usePermissions();
  const [showCreateSource, setShowCreateSource] = useState(false);
  const { sources } = useGetSourcesInGroup();
  const { id, title, description, year } = source;
  const [mutationModifySource] = useMutation(gql(modifySource), {
    onCompleted: () => {
      ToastifyQueue("Source Updated Successfully.", "success");
    },
    onError: ({ message }: ApolloError) => {
      ToastifyQueue(message, "danger");
    },
  });

  const sourceAttributesSchema = useMemo(
    () =>
      yup.object<Partial<UpdateSourceInput>>({
        title: yup
          .string()
          .default(source?.title)
          .required()
          .label("Title")
          .matches(/^(?!\s+$).*/, "This field cannot contain only blank spaces")
          .test(
            "check-duplication",
            "Source title already exists!",
            (value) => {
              return !isUniqueTitle(
                sources.filter((i) => i.id !== source?.id),
                value
              );
            }
          ),
        organizationID: yup
          .string()
          .default(organization?.id)
          .required()
          .label("OrganizationID"),
        groupID: yup.string().default(group?.id).required().label("GroupID"),
        description: yup
          .string()
          .default(source?.description)
          .label("Description"),
        year: yup
          .string()
          .label("Year")
          .test(
            "is-date",
            // eslint-disable-next-line
            "${path} should be in YYYY format",
            (value) => {
              if (value) {
                const date = DateTime.fromFormat(value, "yyyy");
                return date?.isValid;
              }
              return true;
            }
          ),
        archived: yup.boolean().required().default(false),
      }),
    [source, sources]
  );

  function Confirmation() {
    if (window.confirm(cancelPopupMessage)) {
      setShowCreateSource(false);
    }
  }
  return (
    <>
      <Button
        onClick={() => {
          setShowCreateSource(true);
        }}
        style={{ marginRight: "5px" }}
      >
        {groupRole !== "read" ? "Edit" : "View"}
      </Button>
      <Modal
        show={!!showCreateSource}
        onHide={() => {
          Confirmation();
        }}
      >
        <HGForm
          key="form_createSource"
          onSubmit={async (input) => {
            const variables: ModifySourceMutationVariables = {
              input: [
                {
                  id,
                  title: input.title,
                  description: input.description,
                  year: input.year,
                },
              ],
            };
            await mutationModifySource({ variables }).then(onSave);
            setShowCreateSource(false);
          }}
          resolver={sourceAttributesSchema}
        >
          <Modal.Header>
            {groupRole !== "read" ? "Edit Source" : "Source"}
          </Modal.Header>
          <Modal.Body>
            <HGInputBox
              label="Title"
              name="title"
              defaultValue={title}
              required
            />
            <HGNumber label="Year" name="year" defaultValue={year} />
            <HGRichText
              label="Description"
              name="description"
              defaultValue={description}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                Confirmation();
              }}
            >
              Cancel
            </Button>
            <HGFormSubmit />
          </Modal.Footer>
        </HGForm>
      </Modal>
    </>
  );
};
