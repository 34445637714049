import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { IssueType } from "./issueTypes";

import { Paragraph, AlignmentType, TextRun } from "docx";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { DateTime } from "luxon";
import { getItemApprover } from "utils/dataFormatters";
import { formatPriority, formatSeverity, formatStatus } from "utils/formatStatus";
import { usePermissions } from "common/Permissions";
import {
  useGetGroupByRelatedItem,
  relatedItemsParse,
} from "utils/useGetGroupByRelatedItems";

export const issueReportSchema = (): ReportConfig<IssueType> => {
  const getFilename = useGenerateReportFilename();
  const { getOrganizationUser, groups } = usePermissions();
  return {
    getFilename: (item) =>
      getFilename({
        type: "filename",
        fileName: `Decipher Risk - ISSUE ${item.title ?? "title"}`,
        timestamp: DateTime.now(),
      }),
    fields: [
      { type: "Header", content: (item) => item.title ?? "" },
      {
        type: "InlineText",
        label: "Status",
        content: (item) => formatStatus(item?.status),
      },
      {
        type: "InlineText",
        label: "Approver",
        content: (item) => getItemApprover(item, "issueApprovers"),
      },
      {
        type: "InlineText",
        label: "Priority",
        content: (item) => formatPriority(item?.priority),
      },
      {
        type: "InlineText",
        label: "Severity",
        content: (item) => formatSeverity(item.severity),
      },
      {
        type: "InlineText",
        label: "Created At",
        content: (item: any) =>
          DateTime.fromISO(item?.createdAt)
            .toLocaleString(DateTime.DATETIME_MED)
            .replace(/,/g, "") ?? "",
      },
      {
        type: "InlineText",
        label: "Last Modified",
        content: (item: any) =>
          DateTime.fromISO(item?.lastModified)
            .toLocaleString(DateTime.DATETIME_MED)
            .replace(/,/g, ""),
      },
      {
        type: "InlineText",
        label: "Owner",
        content: (item) => getOrganizationUser(item?.owner)?.displayName || "Not Set",
      },
      {
        type: "InlineText",
        label: "Next Review",
        content: (item) => item?.nextReview,
      },
      {
        type: "InlineText",
        label: "Reported On",
        content: (item) => item?.reportedOn,
      },
      {
        type: "InlineText",
        label: "Source",
        content: (item) => item?.issueSource?.title ?? "Not Set",
      },
      {
        type: "InlineText",
        label: "Reported By",
        content: (item) => item.reportedBy ?? "",
      },
      {
        type: "RichText",
        label: "Description",
        content: (item) => item.description ?? "",
      },
      {
        type: "RichText",
        label: "Notes",
        content: (item) => item.notes ?? "",
      },
      {
        type: "RichText",
        label: "Resolution Criteria",
        content: (item) => item.acceptanceCriteria ?? "",
      },
      {
        type: "RichText",
        label: "Assumptions",
        content: (item) => item.assumptions ?? "",
      },
      {
        type: "Custom",
        label: "Related Items",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            heading: "Heading2",
            children: [
              new TextRun({ text: "Related Items", bold: true, break: 1 }),
            ],
          }),
          ...relatedItemsParse(
            useGetGroupByRelatedItem(item?.issueRelatedItems?.items, groups)
          ),
        ],
      },
    ],
  };
};
