import React from "react";
import { Card, Col, FormControl, Row } from "react-bootstrap";
import { useGetIssuesInGroup } from "utils/connectors/issueConnectors";
import { usePermissions } from "common/Permissions";
import { useGetSourcesInGroup } from "Modules/Customers/Issues/IssuePage/Sources";
import { useGetActionPlansInGroup } from "utils/connectors/actionPlanConnectors";

import { issueStats } from "./issueStats";
import { actionPlanStats } from "./actionPlanStats";
import { useTabs } from "common/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faFirstAid } from "@fortawesome/free-solid-svg-icons";
import { Status } from "API";

const inlineReportMap = (items, config) => (fieldMap) => {
  const item = fieldMap(items, config);
  return (
    <p>
      {item?.label && (
        <span style={{ fontWeight: "bold" }}>{item.label}: </span>
      )}
      {item?.text && <span>{item?.text}</span>}
      {item?.listItems && (
        <ul>
          {item.listItems.map((i) => (
            <li>{i}</li>
          ))}
        </ul>
      )}
    </p>
  );
};

const SummaryColorPanel = ({ icon, smallText, largeText, color, ...props }) => {
  return (
    <Col {...props}>
      <Card className={`widget ${color()}-bg text-center`}>
        <Row>
          <Col sm={4}>
            <FontAwesomeIcon icon={icon} size="5x" />
          </Col>
          <Col className="text-right" sm={8}>
            <span>{smallText}</span>
            <h2>{largeText}</h2>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export const SystemUsageReport = () => {
  const { issues } = useGetIssuesInGroup();
  const { actionPlans } = useGetActionPlansInGroup();
  const { sources } = useGetSourcesInGroup();
  const { organizationUsers } = usePermissions();

  const _issues = issues.filter(({ archived }) => !archived);
  const _actionPlans = actionPlans.filter(({ archived }) => !archived);

  const issueTabOptions = [
    "All",
    "Active",
    "With No Active Action Plans",
    "With Active Action Plans",
    "Closed",
  ];
  const [issueTab, setIssueTab] = useTabs(issueTabOptions);
  const filteredIssues = {
    Active: _issues.filter(
      (issue) =>
        issue.status !== Status.Deferred &&
        issue.status !== Status.Closed &&
        issue.status !== Status.Closed_Pending_Approval
    ),
    "With No Active Action Plans": _issues.filter(
      (issue) =>
        issue.issueRelatedItems.items.filter(
          (i) => i.targetType === "actionplans"
        ).length === 0
    ),
    "With Active Action Plans": _issues.filter(
      (issue) =>
        issue.status !== Status.Closed &&
        issue.status !== Status.Closed_Pending_Approval &&
        issue.issueRelatedItems.items.filter(
          (i) => i.targetType === "actionplans"
        ).length > 0
    ),
    Closed: _issues.filter(
      (actionPlan) =>
        actionPlan.status === Status.Closed ||
        actionPlan.status === Status.Closed_Pending_Approval
    ),
    All: _issues,
  };
  const activeIssues = filteredIssues[issueTab];

  const actionPlanTabOptions = [
    "All",
    "Active",
    "With No Active Issues",
    "With Active Issues",
    "Closed",
  ];

  const [actionPlanTab, setActionPlanTab] = useTabs(actionPlanTabOptions);
  const filteredActionPlans = {
    Active: _actionPlans.filter(
      (actionPlan) =>
        actionPlan.status !== Status.Deferred &&
        actionPlan.status !== Status.Closed &&
        actionPlan.status !== Status.Closed_Pending_Approval
    ),
    "With No Active Issues": _actionPlans.filter(
      (actionPlan) =>
        actionPlan.actionPlanRelatedItems.items.filter(
          (i) => i.targetType === "issues"
        ).length === 0
    ),
    "With Active Issues": _actionPlans.filter(
      (actionPlan) =>
        actionPlan.status !== Status.Deferred &&
        actionPlan.status !== Status.Closed &&
        actionPlan.status !== Status.Closed_Pending_Approval &&
        actionPlan.actionPlanRelatedItems.items.filter(
          (i) => i.targetType === "issues"
        ).length > 0
    ),
    Closed: _actionPlans.filter(
      (actionPlan) =>
        actionPlan.status === Status.Closed ||
        actionPlan.status === Status.Closed_Pending_Approval
    ),
    All: _actionPlans,
  };
  const activeActionPlans = filteredActionPlans[actionPlanTab];

  const summaryStats = {
    issuesWithReviewDates:
      _issues.length < 1
        ? 0
        : Math.round(
            (100 *
              filteredIssues["Active"].filter(({ nextReview }) => !!nextReview)
                .length) /
              filteredIssues["Active"].length
          ),
    issuesWithActionPlans:
      _issues.length < 1
        ? 0
        : Math.round(
            (100 * filteredIssues["With Active Action Plans"].length) /
              filteredIssues["Active"].length
          ),
    actionPlansWithReviewDates:
      _actionPlans.length < 1
        ? 0
        : Math.round(
            (100 *
              filteredActionPlans["Active"].filter(
                ({ reviewDate }) => !!reviewDate
              ).length) /
              filteredActionPlans["Active"].length
          ),
    actionPlansWithIssues:
      _actionPlans.length < 1
        ? 0
        : Math.round(
            (100 * filteredActionPlans["With Active Issues"].length) /
              filteredActionPlans["Active"].length
          ),
  };
  return (
    <>
      {/* <Col xs="12">
        <GenerateZip/>
      </Col> */}
      <SummaryColorPanel
        sm={3}
        color={() => {
          if (summaryStats.issuesWithReviewDates >= 90) return "navy";
          if (summaryStats.issuesWithReviewDates >= 50) return "yellow";
          return "red";
        }}
        icon={faBug}
        smallText="Active Issues With Review Dates"
        largeText={`${summaryStats.issuesWithReviewDates.toString()}%`}
      />
      <SummaryColorPanel
        sm={3}
        color={() => {
          if (summaryStats.issuesWithActionPlans >= 80) return "navy";
          if (summaryStats.issuesWithActionPlans >= 50) return "yellow";
          return "red";
        }}
        icon={faBug}
        smallText="Active Issues With Action Plans"
        largeText={`${summaryStats.issuesWithActionPlans.toString()}%`}
      />
      <SummaryColorPanel
        sm={3}
        color={() => {
          if (summaryStats.actionPlansWithReviewDates >= 90) return "navy";
          if (summaryStats.actionPlansWithReviewDates >= 50) return "yellow";
          return "red";
        }}
        icon={faFirstAid}
        smallText="Active Action Plans With Review Dates"
        largeText={`${summaryStats.actionPlansWithReviewDates.toString()}%`}
      />
      <SummaryColorPanel
        sm={3}
        color={() => {
          if (summaryStats.actionPlansWithIssues >= 80) return "navy";
          if (summaryStats.actionPlansWithIssues >= 50) return "yellow";
          return "red";
        }}
        icon={faFirstAid}
        smallText="Active Action Plans With Issues"
        largeText={`${summaryStats.actionPlansWithIssues.toString()}%`}
      />
      <Col sm={6}>
        <Card>
          <Card.Header>
            <div style={{ float: "right" }}>
              <FormControl
                as="select"
                onChange={(e) => setIssueTab(e.target.value)}
              >
                {issueTabOptions.map((ap) => (
                  <option value={ap}>{ap}</option>
                ))}
              </FormControl>
            </div>
            Issues
          </Card.Header>
          <Card.Body>
            <p>This is the status report for items in the Issues Register.</p>
            {(activeIssues?.length > 0 &&
              issueStats.map(
                inlineReportMap(activeIssues, { organizationUsers, sources })
              )) ||
              "No relevant Issues found."}
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6}>
        <Card>
          <Card.Header>
            <div style={{ float: "right" }}>
              <FormControl
                as="select"
                onChange={(e) => setActionPlanTab(e.target.value)}
              >
                {actionPlanTabOptions.map((ap) => (
                  <option value={ap}>{ap}</option>
                ))}
              </FormControl>
            </div>
            Action Plans
          </Card.Header>
          <Card.Body>
            <p>
              This is the status report for all items in the Action Plan
              Register.
            </p>
            {(activeActionPlans?.length > 0 &&
              actionPlanStats.map(
                inlineReportMap(activeActionPlans, {
                  organizationUsers,
                  sources,
                })
              )) ||
              "No relevant Action Plans found."}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
