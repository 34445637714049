import { DateTime } from "luxon";
import React from "react";
import { Card } from "react-bootstrap";
import { dateFromCustomFormat } from "utils/dataFormatters";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useHistory } from "react-router";
import { useGetAuditPlansInGroup } from "utils/connectors/auditPlanningConnectors";
import useSetTitle from "utils/useSetTitle";


const localizer = momentLocalizer(moment);

export const RiskDomainCalendar = () => {
  const { auditPlans }: any = useGetAuditPlansInGroup();
  useSetTitle("Audit Calendar");
  const data = auditPlans
    .filter(
      (item) =>
        item?.auditStartDate &&
        (dateFromCustomFormat(item?.auditStartDate, "MM/dd/yyyy") as any) <
          DateTime.local().plus({ days: 30 })
    )
    .map((element) => {
      return { ...element, allDay: true };
    });
  const history = useHistory();
  const handleSelected = (event) => {
    if (event.__typename === "AuditPlanning") {
      history.push(`/audits/${event.id}`);
    }
  };
  return (
    <div style={{ paddingTop: "10px" }}>
      <Card>
        <Card.Header>
          <span className="h5">Audit Calendar</span>
        </Card.Header>
        <Card.Body>
          <Calendar
            localizer={localizer}
            events={data || []}
            startAccessor="auditStartDate"
            endAccessor="auditEndDate"
            style={{ height: 500 }}
            tooltipAccessor={(e) => `${e.title}-new`}
            onSelectEvent={handleSelected}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
