import { ReportConfig } from "utils/docx-export/reportSchemaParse.types";
import { ActionPlanType } from "./actionPlanTypes";
import { Paragraph, AlignmentType, TextRun } from "docx";
import { useGenerateReportFilename } from "utils/docx-export/useCreateFilename";
import { DateTime } from "luxon";
import { getItemApprover } from "utils/dataFormatters";
import { formatPriority, formatStatus } from "utils/formatStatus";
import { usePermissions } from "common/Permissions";
import {
  useGetGroupByRelatedItem,
  relatedItemsParse,
} from "utils/useGetGroupByRelatedItems";
import { EffortType } from "API";
import { createActionPlanTaskTable } from "./actionPlanReportUtils";

export const actionPlanReportSchema = (): ReportConfig<ActionPlanType> => {
  const getFilename = useGenerateReportFilename();
  const { getOrganizationUser, groups } = usePermissions();
  return {
    getFilename: (item) =>
      getFilename({
        type: "filename",
        fileName: `Decipher Risk - Action Plan ${item.title ?? "title"}`,
        timestamp: DateTime.now(),
      }),
    fields:[
      { type: "Header", content: (item) => item.title ?? "" },
      {
        type: "InlineText",
        label: "Status",
        content: (item) => formatStatus(item?.status),
      },
      {
        type: "InlineText",
        label: "Approver",
        content: (item) => getItemApprover(item, "actionplanApprovers"),
      },
      {
        type: "InlineText",
        label: "Priority",
        content: (item) => formatPriority(item.priority),
      },
      {
        type: "InlineText",
        label: "Effort Amount",
        content: (item) => item?.effortAmount === -1 || item?.effortAmount === null  ? "Not Set" : `${item?.effortAmount} ${item?.effortType || Object.values(EffortType)[0]}`,
      },
      {
        type: "InlineText",
        label: "Created At",
        content: (item: any) =>
          DateTime.fromISO(item?.createdAt)
            .toLocaleString(DateTime.DATETIME_MED)
            .replace(/,/g, "") ?? "",
      },
      {
        type: "InlineText",
        label: "Last Modified",
        content: (item: any) =>
          DateTime.fromISO(item?.lastModified)
            .toLocaleString(DateTime.DATETIME_MED)
            .replace(/,/g, ""),
      },
      {
        type: "InlineText",
        label: "Owner",
        content: (item) => getOrganizationUser(item?.owner)?.displayName,
      },
      {
        type: "InlineText",
        label: "Start Date",
        content: (item) => item.startDate,
      },
      {
        type: "InlineText",
        label: "End Date",
        content: (item) => item.dueDate,
      },
      {
        type: "InlineText",
        label: "Review Date",
        content: (item) => item.reviewDate,
      },
      {
        type: "RichText",
        label: "Description",
        content: (item) => item.description ?? "",
      },
      {
        type: "RichText",
        label: "Resources",
        content: (item) => item.resources ?? "",
      },
      {
        type: "RichText",
        label: "Notes",
        content: (item) => item.notes ?? "",
      },
      {
        type: "Custom",
        label: "",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            heading: "Heading2",
            children: [
              new TextRun({ text: "Tasks", bold: true, break: 1 }),
            ],
          }),
          createActionPlanTaskTable(item.taskChildren?.items.map((item: any)=>{
            return {
              ...item,
              task: {
                ...item.task,
                status: item?.task.status === "Closed_Pending_Approval" ? "Closed" : item?.task.status,
              },
            };
          })),
        ],
      },
      {
        type: "Custom",
        label: "Related Items",
        formatting: (item) => [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            heading: "Heading2",
            children: [
              new TextRun({ text: "Related Items", bold: true, break: 1 }),
            ],
          }),
          ...relatedItemsParse(
            useGetGroupByRelatedItem(item?.actionPlanRelatedItems?.items, groups),
          ),
        ],
      },    
    ],
  }
}